import React, { useState, useEffect, useCallback } from "react";
import { Grid, Button, Alert, Stack, Box } from "@mui/material";
import { TextField, Checkboxes, DateTimePicker, makeValidate, makeRequired } from "mui-rff";
import { formatInTimeZone } from "date-fns-tz";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import { Form } from "react-final-form";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import { DATABASE_URL } from "../../appConstants";
import { useParams, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { useCEFRconverter } from "../../hooks/useCEFRconverter";
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

import "./Candidate.css";

const UpdateCandidate = () => {
  const { id } = useParams();
  const [candidateDetails, setCandidateDetails] = useState({});
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();
  const { isLoading, startLoading, stopLoading } = useLoadingContext();

  useEffect(() => {
    fetchCandidateDetails(id);
  }, [id]);

  const fetchCandidateDetails = async (candidateId) => {
    startLoading();
    try {
      const response = await Axios.get(`${DATABASE_URL}/candidate/${candidateId}`, {
        headers: AuthHeader(),
      });
      const candidate = response.data.candidate[0];
      candidate.grade = determineGrade(candidate);

      // Convert the stored UTC time back to the candidate's timezone for display
      if (candidate.test_date && candidate.time_zone) {
        candidate.test_date = formatInTimeZone(
          new Date(candidate.test_date),
          candidate.time_zone,
          "yyyy-MM-dd'T'HH:mm:ssXXX"
        );
      }

      setCandidateDetails(candidate);
    } catch (error) {
      console.error('Error fetching candidate details:', error);
    } finally {
      stopLoading();
    }
  };

  const determineGrade = (candidate) => {
    if (!candidate.grade && candidate.completed_test) {
      return "Waiting for Grading";
    }
    if (!candidate.completed_test) {
      return "Waiting for Test";
    }
    return useCEFRconverter(candidate.grade);
  };

  // Format date maintaining the timezone
  const formatTestDate = (date, timezone) => {
    const formatted = formatInTimeZone(date, timezone, "yyyy-MM-dd HH:mm:ss");
    return formatted;
  };

  const enableEdit = () => {
    setIsDisabled(false);
  };

  const schema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    government_id: Yup.string().required("Government ID is required"),
    change_pass: Yup.boolean(),
    new_pass: Yup.string().when("change_pass", {
      is: true,
      then: Yup.string().min(8, "Minimum of 8 characters in a password").max(24, "Maximum of 24 characters in a password").required("Must enter new password"),
    }),
    retype_pass: Yup.string().when("change_pass", {
      is: true,
      then: Yup.string().required("Must retype new password").oneOf([Yup.ref("new_pass"), null], "Passwords must match"),
    }),
  });

  const validate = makeValidate(schema);
  const required = makeRequired(schema);

  const handleDownloadCertificate = useCallback((id) => {
    navigate(`/candidate/certificate/${id}`, { candidateDetails });
  }, [candidateDetails, navigate]);

  const onSubmit = async (values) => {
    startLoading();
    setIsDisabled(true);
    try {
      // Update password if changed
      values.password = values.change_pass ? values.new_pass : values.password;

      // Format the test date maintaining the timezone
      values.test_date = formatTestDate(values.test_date, candidateDetails.time_zone);
      values.time_zone = candidateDetails.time_zone;

      await Axios.put(`${DATABASE_URL}/candidate/${values.id}`, values, { headers: AuthHeader() });
      setMessage("Candidate Updated!");

      // Refresh candidate details after update
      await fetchCandidateDetails(values.id);
    } catch (error) {
      const errorMessage = (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.error
      ) || "Something went wrong!";

      setMessage(errorMessage);
      console.error('Error updating candidate:', error);
    } finally {
      stopLoading();
    }
  };

  const onCancel = () => {
    navigate("/candidate/manage-candidate");
  };

  const renderFormFields = () => {
    return [
      { label: "First Name", name: "first_name", type: "text", required: required.first_name },
      { label: "Last Name", name: "last_name", type: "text", required: required.last_name },
      { label: "Email", name: "email", type: "email", required: required.email },
      { label: "Government ID", name: "government_id", type: "text", required: required.government_id },
      { label: "Security Flags", name: "flag", type: "text", disabled: true },
      { label: "Password", name: "new_pass", type: "password", required: required.new_pass },
      { label: "Re-enter Password", name: "retype_pass", type: "password", required: required.retype_pass },
      {
        component: (
          <Box className="candidate-image" sx={{ width: "100%", height: 200, border: "1px solid #b8b8b8", borderRadius: "4px" }}>
            <img src={candidateDetails.image_string} alt="candidate" />
          </Box>
        ),
      },
      {
        component: (
          <Box sx={{ width: "100%", height: 200, border: "1px solid #b8b8b8", borderRadius: "4px", padding: 2 }}>
            {candidateDetails.completed_test && candidateDetails.grade !== 'Waiting for Grading' && candidateDetails.grade !== 'Waiting for Test' ? (
              <center onClick={handleDownloadCertificate(id)}>
                Click on the Icon to download the certificate
                <div>
                  <img src="/images/pdf-logo.png" alt="logo" />
                </div>
              </center>
            ) : (
              <center>Certificate will be ready when grading is finished</center>
            )}
          </Box>
        ),
      },
      { component: <DateTimePicker label="Test Date" name="test_date" disabled={isDisabled} dateFnsUtils={DateFnsUtils} initialValue={candidateDetails.test_date ? new Date(candidateDetails.test_date) : null} /> },
      { label: "Overall Score", name: "grade", type: "text", disabled: true },
      { label: "CEFR", name: "cefr", type: "text", disabled: true },
      { component: <Checkboxes name="change_pass" required={required.change_pass} disabled={isDisabled} data={{ label: "Set new password?", value: false }} /> },
    ].map((field, index) => (
      <Grid item xs={3} key={index}>
        {field.component || <TextField {...field} disabled={isDisabled} />}
      </Grid>
    ));
  };

  return (
    <div>
      <Stack spacing={2}>
        <div className="title-with-button">
          <h1>Candidate Details</h1>
          <Button variant="outlined" size="large" startIcon={<EditIcon />} onClick={enableEdit} disabled={!isDisabled || candidateDetails.completed_test}>
            Enable Edit
          </Button>
        </div>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <>
            {message && <Alert severity="success">{message}</Alert>}
            <Form
              onSubmit={onSubmit}
              validate={validate}
              initialValues={candidateDetails}
              render={({ hasSubmitErrors, handleSubmit, submitting, form, values }) => (
                <form onSubmit={handleSubmit} className="add-user-form">
                  <Grid container direction="row" spacing={4}>
                    {renderFormFields()}
                  </Grid>
                  <Grid container spacing={4} className="form-button-group">
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting || isDisabled}>
                        Update
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="large" onClick={onCancel} disabled={submitting}>
                        CANCEL
                      </Button>
                    </Grid>
                  </Grid>
                  {hasSubmitErrors && <Alert severity="error">error</Alert>}
                </form>
              )}
            />
          </>
        )}
      </Stack>
    </div>
  );
};

export default UpdateCandidate;
