import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";
import { Grid, Button, Alert, Stack, MenuItem } from "@mui/material";
import {
  TextField,
  Select,
  DatePicker,
  DateTimePicker,
  makeValidate,
  makeRequired,
  Checkboxes
} from "mui-rff";
import { formatInTimeZone } from "date-fns-tz";
import DateFnsUtils from "@date-io/date-fns";
import * as Yup from "yup";
import { Form } from "react-final-form";
import { TYPE_OF_TEST, DATABASE_URL } from "../../appConstants";
import AuthHeader from "../../services/AuthHeader";
import Axios from "axios";
import * as XLSX from "xlsx/xlsx.js";
import countryList from 'react-select-country-list';
import moment from 'moment-timezone';
import { useLoadingContext } from "../../hooks/useLoadingContext";
import { Loader } from "../../components/common";

// Validation schema for the form fields using Yup
const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  last_name: Yup.string().required("Last Name is required"),
  email: Yup.string().email().required("Email is required"),
  government_id: Yup.string().required("Government ID is required"),
  date_of_birth: Yup.date("Invalid date").required("Date of Birth is required"),
  test_date: Yup.date("Invalid date").required("Test date is required"),
  gender: Yup.string().required("Gender is required"),
  type_of_test_id: Yup.string().required("Please select type of the test"),
  password: Yup.string()
    .min(8, "Minimum of 8 characters in a password")
    .max(24, "Maximum of 24 characters in a password")
    .required("Must enter new password"),
  retype_password: Yup.string()
    .required("Must retype new password")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
  timezone: Yup.string().required("Please select a timezone"),
  send_email: Yup.boolean()
});

// Final-form validation configuration
const validate = makeValidate(validationSchema);
const required = makeRequired(validationSchema);

const AddCandidate = () => {
  const { user } = useAuthContext();  // User context for authentication
  const { isLoading, startLoading, stopLoading } = useLoadingContext();  // Loading context for showing loader

  const [message, setMessage] = useState("");  // Success message state
  const [errMessage, setErrMessage] = useState("");  // Error message state
  const [selectedCountryCode, setSelectedCountryCode] = useState("");  // Selected country code state
  const [selectedTimezones, setSelectedTimezones] = useState([]);  // Selected timezones state
  const [formData, setFormData] = useState({});  // Form data state

  // Memoized list of countries for select dropdown
  const countries = useMemo(() => countryList().getData(), []);

  // Effect to update timezones when selected country changes
  useEffect(() => {
    const timezones = moment.tz.zonesForCountry(selectedCountryCode);
    if (timezones) {
      setSelectedTimezones(timezones);
    }
  }, [selectedCountryCode]);

  // Handle country change event
  const handleCountryChange = (e) => {
    setSelectedCountryCode(e.target.value);
  };

  const formatDate = useCallback((date, timezone) => {
    const formatted = formatInTimeZone(date, timezone, "yyyy-MM-dd HH:mm:ss");
    return formatted;
  }, []);

  // Handle form submission
  const handleSubmit = async (values) => {
    setMessage("");
    setErrMessage("");

    if (!values.timezone) {
      setErrMessage("Please select a timezone");
      return;
    }

    // Format dates with appropriate timezones
    values.date_of_birth = formatDate(values.date_of_birth, Intl.DateTimeFormat().resolvedOptions().timeZone);
    values.test_date = formatDate(values.test_date, values.timezone);

    // Find and set selected country label
    const selectedCountry = countries.find(c => c.value === selectedCountryCode);
    if (!selectedCountry) {
      setErrMessage("Please select a valid country");
      return;
    }
    values.country = selectedCountry.label;

    startLoading();
    setFormData(values);

    try {
      const response = await Axios.post(
        `${DATABASE_URL}/candidate`,
        { ...values, test_center_id: user.test_center },
        { headers: AuthHeader() }
      );
      setMessage("New Candidate Created!");
      console.log(response.data);
    } catch (error) {
      setErrMessage(error.response.data.error);
      console.log(error);
    } finally {
      stopLoading();
    }
  };

  // Handle file upload for importing candidates
  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const json = XLSX.utils.sheet_to_json(worksheet);

        startLoading();
        try {
          const response = await Axios.post(
            `${DATABASE_URL}/candidate/import`,
            { data: json, test_center: user.test_center },
            { headers: AuthHeader() }
          );
          setMessage(response.data.message);
          console.log(response.data);
        } catch (error) {
          setErrMessage(error.response.data.error);
        } finally {
          stopLoading();
        }
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  // Render form fields dynamically
  const renderFormFields = () => (
    [
      <TextField label="First Name" name="first_name" placeholder="First Name" type="text" required={required.first_name} />,
      <TextField label="Last Name" name="last_name" placeholder="Last Name" type="text" required={required.last_name} />,
      <TextField label="Email" name="email" placeholder="Email" type="email" required={required.email} />,
      <DatePicker label="Date of Birth" name="date_of_birth" required={required.date_of_birth} dateFnsUtils={DateFnsUtils} />,
      <TextField label="Government ID" name="government_id" placeholder="Government ID" type="text" required={required.government_id} />,
      <Select label="Gender" name="gender" placeholder="Gender">
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>,
      <TextField label="Password" name="password" placeholder="Password" type="password" required={required.password} />,
      <TextField label="Re-enter Password" name="retype_password" placeholder="Re-enter Password" type="password" required={required.retype_password} />,
      <Select label="Country" name="country" placeholder="Country" onChange={handleCountryChange} value={selectedCountryCode} required={true}>
        {countries.map((c, idx) => <MenuItem value={c.value} key={`country-${idx}`}>{c.label}</MenuItem>)}
      </Select>,
      <Select label="Timezone" name="timezone" placeholder="Timezone" disabled={!selectedTimezones.length}>
        {selectedTimezones.map((t, idx) => <MenuItem value={t} key={`timezone-${idx}`}>{t}</MenuItem>)}
      </Select>,
      <Select label="Type of Test" name="type_of_test_id" placeholder="Type of Test">
        {TYPE_OF_TEST.map((o, idx) => <MenuItem value={idx} key={`test-${idx}`}>{o}</MenuItem>)}
      </Select>,
      <DateTimePicker label="Test Date" name="test_date" required={true} dateFnsUtils={DateFnsUtils} />,
      <Checkboxes name="send_email" data={{ label: "Do you want to send email to candidate?", value: true }} />
    ]
  );

  return (
    <div>
      <Stack spacing={2}>
        <h1>Add New Candidate</h1>
        {isLoading ? (
          <div className="loader-display">
            <Loader />
          </div>
        ) : (
          <>
            {message && <Alert severity="success">{message}</Alert>}
            {errMessage && <Alert severity="warning">{errMessage}</Alert>}
            <Form
              onSubmit={handleSubmit}
              validate={validate}
              initialValues={{ send_email: false, ...formData }}
              render={({ handleSubmit, submitting }) => (
                <form onSubmit={handleSubmit} className="add-user-form">
                  <Grid container direction="row" spacing={4}>
                    {renderFormFields().map((field, index) => (
                      <Grid item xs={6} key={index}>
                        {field}
                      </Grid>
                    ))}
                  </Grid>
                  <Grid container spacing={4} className="form-button-group" justifyContent="flex-end">
                    <Grid item>
                      <Button variant="contained" color="primary" type="submit" size="large" disabled={submitting}>
                        CREATE
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="contained" color="success" size="large" component="label" onChange={handleFileUpload}>
                        Import
                        <input type="file" hidden />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button variant="outlined" color="primary" size="large">
                        <a href="/candidate_template.xlsx" download>
                          Template
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            />
          </>
        )}
      </Stack>
    </div>
  );
};

export default AddCandidate;
